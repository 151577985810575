import addressList from '../assets/js/address'
// import config from '@/config/index'
const cityList = addressList.areaList.city_list;
const countyList = addressList.areaList.county_list;


const formatCity = (useKey, num, disableArr) => {
    let array = [];
    let disabledNum = 0;
    let nowarray = num == 1 ? cityList : num == 2 ? countyList : [];
    for (let key in nowarray) {
        if (key.substring(0, num * 2) == useKey.substring(0, num * 2)) {
            let temp = {
                id: key,
                name: nowarray[key],
                disabled: disableArr.indexOf(key) != -1
            }
            if (num == 1) {
                let children = formatCity(key, 2, disableArr);
                temp.children = children.array;
                if (children.disabledNum >= children.array.length && children.array.length != 0) {
                    temp.disabled = true;
                }
            }
            if (temp.disabled) {
                disabledNum++;
            }
            array.push(JSON.parse(JSON.stringify(temp)));
        }
    }
    return {
        array: array,
        disabledNum: disabledNum
    };
}

export default {
    //根据0和1切换true和false
    toBoolean(val) {
        return val == 0 ? false : true;
    },
    //获取城数据
    getAddressList(disableArr = []) {
        let tempCitylist = [];
        let provinceList = addressList.areaList.province_list;
        console.log(disableArr);
        for (let key in provinceList) {
            let children = formatCity(key, 1, disableArr);
            tempCitylist.push({
                id: key,
                name: provinceList[key],
                children: children.array,
                disabled: children.disabledNum == children.array.length
            });
        }
        // console.log(tempCitylist);
        return tempCitylist;
    },
    //下载文件
    convertRes2Blob(response) {
        console.log(response);
        // 提取文件名
        const fileName = response.headers['content-disposition'].match(
            /filename=(.*)/
        )[1]
        // 将二进制流转为blob
        const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel;charset=UTF-8'
        })
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(fileName))
        } else {
            // 创建新的URL并指向File对象或者Blob对象的地址
            const blobURL = window.URL.createObjectURL(blob)
            // 创建a标签，用于跳转至下载链接
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', decodeURI(fileName))
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank')
            }
            // 挂载a标签
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            // 释放blob URL地址
            window.URL.revokeObjectURL(blobURL)
        }
    }
}